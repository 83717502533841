.bgcolor {
  background-color: #22c77c !important;
}
.displayInline {
  display: inline-block;
}
.noBoxShadow:focus {
  box-shadow: none;
}
.color {
  color: #22c77c;
}
.pointer {
  cursor: pointer !important;
}
.text-right {
  text-align: right !important;
}
.w-45 {
  width: 45% !important;
}
.w-50 {
  width: 50% !important;
}
.w-60 {
  width: 60% !important;
}
.w-70 {
  width: 70% !important;
}
.textDecorationNone {
  text-decoration: none;
  color: black;
}
.textDecorationNone:hover {
  color: #000000;
}

.borderNone {
  border: none;
}
.underlineAnimation {
  position: relative;
}

.underlineAnimation::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 3px;
  bottom: -5px;
  left: 0;
  background-color: #3cd884 !important;
  transform-origin: bottom right;
  transition: transform 0.3s ease-in-out;
}

.underlineAnimation:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
.floatRight {
  float: right;
}
.floatLeft {
  float: left;
}
.w-23px {
  width: 23px !important;
}
.w-24px {
  width: 24px !important;
}
.w-25px {
  width: 25px !important;
}
.w-26px {
  width: 26px !important;
}
.w-27px {
  width: 27px !important;
}
.w-28px {
  width: 28px !important;
}
.w-29px {
  width: 29px !important;
}
.w-30px {
  width: 30px !important;
}

.w-35px {
  width: 35px !important;
}
.f0 {
  font-size: 0px;
}
.f1 {
  font-size: 1px;
}
.f2 {
  font-size: 2px;
}
.f3 {
  font-size: 3px;
}
.f4 {
  font-size: 4px;
}
.f5 {
  font-size: 5px;
}
.f6 {
  font-size: 6px;
}
.f7 {
  font-size: 7px;
}
.f8 {
  font-size: 8px;
}
.f9 {
  font-size: 9px;
}
.f10 {
  font-size: 10px;
}
.f11 {
  font-size: 11px;
}
.f12 {
  font-size: 12px;
}
.f13 {
  font-size: 13px;
}
.f14 {
  font-size: 14px;
}
.f15 {
  font-size: 15px;
}
.f16 {
  font-size: 16px;
}
.f17 {
  font-size: 17px;
}
.f18 {
  font-size: 18px;
}
.f19 {
  font-size: 19px;
}
.f20 {
  font-size: 20px;
}
.f21 {
  font-size: 21px;
}
.f22 {
  font-size: 22px;
}
.f23 {
  font-size: 23px;
}
.f24 {
  font-size: 24px;
}
.f25 {
  font-size: 25px;
}
.f26 {
  font-size: 26px;
}
.f27 {
  font-size: 27px;
}
.f28 {
  font-size: 28px;
}
.f29 {
  font-size: 29px;
}
.f30 {
  font-size: 30px;
}

/* <---- Toggle Button -----> */
.navbarToggler:focus,
.searchBtn:focus,
.searchInput:focus {
  box-shadow: none !important;
}
.navbarToggler {
  border: none;
}
.menu-btn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}
.menu-btn__burger {
  width: 30px;
  height: 5px;
  position: absolute;
  left: 0px;
  background: #6c757d;
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
}
.menu-btn__burger::before,
.menu-btn__burger::after {
  content: '';
  position: absolute;
  right: 0px;
  width: 30px;
  height: 5px;
  background: #6c757d;
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
}
.menu-btn__burger::before {
  transform: translateY(-10px);
}
.menu-btn__burger::after {
  transform: translateY(10px);
}
/* ANIMATION */
.menu-btn.open .menu-btn__burger {
  transform: translateX(-50px);
  background: transparent;
  box-shadow: none;
}
.menu-btn.open .menu-btn__burger::before {
  transform: rotate(45deg) translate(35px, -35px);
}
.menu-btn.open .menu-btn__burger::after {
  transform: rotate(-45deg) translate(35px, 35px);
}
/* <----> */
/* <------Navbar------------> */
.nav-item {
  font-size: 18px;
  color: black !important;
  font-weight: 400;
  position: relative;
  text-decoration: none;
}

.nav-item::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 3px;
  bottom: -5px;
  left: 0;
  background-color: #3cd884 !important;
  transform-origin: bottom right;
  transition: transform 0.25s ease-in-out;
}

.nav-item:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.search {
  height: 40px;
  padding: 10px;
  border-radius: 20px;
  border: 1px solid lightgray !important;
}

.search-input {
  border: 0;
  outline: 0;
  color: gray !important;
}

.search .search-input {
  padding: 0 10px;
  width: 100%;
  font-size: 19px;
  font-weight: 300;
  transition: width 0.4s linear;
}
.search-input::placeholder {
  color: lightgray !important;
  font-size: 20px;
  font-family: FiraSans-regular;
}

.search-icon {
  height: 20px;
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 10px;
  border-radius: 5px;
}
.search-icon::before {
  content: '|';
  font-family: FiraSans-regular;
  color: lightgray;
  padding-right: 5px;
  font-size: 30px;
}
.searchBarDiv {
  width: 65% !important;
}
.searchBar {
  width: 80%;
  position: relative;
  top: 0px;
  bottom: 0px;
  left: 150px;
}
@media screen and (max-width: 1290px) {
  .searchBarDiv {
    width: 61% !important;
  }
  .searchBar {
    width: 70% !important;
    left: 140px;
  }
}
@media screen and (max-width: 1115px) {
  .searchBarDiv {
    width: 55% !important;
  }
  .searchBar {
    width: 70%;
    left: 110px;
  }
}
@media screen and (max-width: 985px) {
  .searchBarDiv {
    width: 50% !important;
  }
  .searchBar {
    width: 70% !important;
    left: 90px;
  }
}
@media screen and (max-width: 880px) {
  .searchBarDiv {
    width: 45% !important;
  }
  .searchBar {
    width: 80% !important;
    left: 55px;
  }
}
@media screen and (max-width: 800px) {
  .searchBarDiv {
    width: 40% !important;
  }
  .searchBar {
    width: 80% !important;
    left: 40px;
  }
}

/* <----------------> */
/* <-----offcanvas-----> */
.dropdown-toggle::after {
  display: none !important;
}
.dropdown-toggle:focus {
  box-shadow: none !important;
}

.dropdownCaretUp {
  animation: dropdownCaretUp 0.3s ease-in-out;
}
@keyframes dropdownCaretUp {
  from {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.dropdownCaretDown {
  animation: dropdownCaretDown 0.3s ease-in-out;
}
@keyframes dropdownCaretDown {
  from {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.categoryDropdownMenu {
  border: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.categoryDropdownMenu::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.categoryDropdownMenu {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* <------------> */
/* <------Secondary Navbar---------> */
.dropdownBtn {
  padding: 7px 15px;
  border-radius: 10px;
  color: whitesmoke;
}
.dropdownBtn:hover {
  color: whitesmoke;
}
.hr {
  border: 0;
  height: 30px;
  border-style: solid;
  border-color: #333;
  border-width: 1px 0 0 0;
  border-radius: 20px;
}
.hr:before {
  display: block;
  content: '';
  height: 30px;
  margin-top: -31px;
  border-style: solid;
  border-color: #333;
  border-width: 0 0 1px 0;
  border-radius: 20px;
}
.whatsappNumber {
  position: relative;
  bottom: 3px;
}
/* <------------> */
/* <-----Poster Carousel-------> */
.carouselImage {
  width: 100%;
  height: 35rem !important;
}
.carousel .carousel-indicators button {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  margin: 7px;
}
.carouselMainDiv {
  position: relative;
  top: 0%;
  bottom: 16px;
}

@media screen and (max-width: 800px) {
  .carouselImage {
    height: 25rem !important;
  }
}
@media screen and (max-width: 550px) {
  .carouselImage {
    height: 20rem !important;
  }
}
/* <------------> */
/* <-----Category carousel-------> */
.slick-slide {
  margin: 0px 0px !important;
}
.sliderItems {
  width: 5.5rem !important;
  margin: auto;
  height: 6.5rem !important;
  text-align: center;
  border-radius: 10px;
  margin-top: 20px;
}
.categoryImage {
  width: 40px;
  margin: auto;
}
.slick-prev {
  position: relative;
  top: -25px;
  margin-right: 20px;
  float: right;
  width: 50px;
  height: 50px;
}
.slick-next {
  position: absolute;
  top: -25px;
  height: 50px;
  width: 50px;
  margin-right: 20px;
}
/* <------------> */
/* <------product Card------> */

.productCard {
  height: 480px;
  width: 16rem;
  cursor: pointer;
  margin: auto;
}
.productCard:hover {
  background: #fff;
  box-shadow:
    0 6px 10px rgba(0, 0, 0, 0.08),
    0 0 6px rgba(0, 0, 0, 0.05);
  transition:
    0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
}
.CardWishlistIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 25px;
  color: #d4eee2 !important;
  background-color: transparent;
  z-index: 100000;
}
.productCardImg {
  height: 18rem;
}
.productCardName {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: inherit;
}
.addCartBtn {
  background-color: #d4eee2 !important;
  float: right;
  margin-top: 10px;
  border-radius: 10px;
  text-align: center;
}
.addCartBtn:focus {
  box-shadow: none !important;
}
.addCartBtn:hover {
  background-color: #d4eee2;
}
.badgePriceDiv {
  position: absolute;
  bottom: 10px;
}

/* <------------> */
/* <-----footer-------> */
.secondaryFooterImg {
  width: 80px;
}
.secondaryFooter {
  background-color: #d4eee2;
}
.secondaryFooterText {
  margin: 'auto 0px';
}

.smallFooter {
  padding: 50px 0px;
}
.accordion-button::after {
  background-image: url('./Assests/imgs/AccordianPlus.png');
  transform: scale(0.7) !important;
}
.accordion-button:not(.collapsed)::after {
  background-image: url('./Assests/imgs/accordianMinus.png');
}
.accordion-button:focus {
  box-shadow: none;
}

.accordion-button {
  background-color: #212529;
  color: white;
}
.accordion-button:not(.collapsed) {
  color: white;
  background-color: #212529;
}
/* <------------> */
